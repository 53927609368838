<template>
	<page-container class="page-add-new-video" asideTitle="新增视频">
		<!-- <template slot="aside">
			<el-form class="select-form" :model="form" label-position="top">
				<el-form-item label="数据库名称">
					<el-input v-model="form.name" />
				</el-form-item>
				<el-form-item label="标签体系">
					<el-cascader v-model="form.tag" :options="videoLabelOptions"></el-cascader>
				</el-form-item>
			</el-form>
		</template> -->
		<content-container title="视频编辑">
			<div slot="title" class="tip-title">
				打
				<span>*</span>
				为必填项
			</div>
			<div class="single-choice-question question-form">
				<el-form
					ref="form"
					:model="form"
					:rules="rules"
					label-width="110px"
					label-position="left"
					size="mini"
				>
					<!-- <el-form-item label="数据库名称">
				<el-input v-model="form.name" />
			</el-form-item> -->
					<el-form-item label="视频" prop="videoId">
						<upload-video
							:fileList="videoList"
							:videoId.sync="form.videoId"
						></upload-video>
					</el-form-item>
					<el-form-item label="视频标题" prop="title">
						<el-input v-model="form.title" placeholder="请输入内容"></el-input>
					</el-form-item>
					<el-form-item label="电视呈现标题" prop="tvTitle">
						<el-input v-model="form.tvTitle" placeholder="请输入内容"></el-input>
					</el-form-item>
					<el-form-item label="内容摘要" prop="abstract">
						<el-input v-model="form.abstract" placeholder="请输入内容"></el-input>
					</el-form-item>
					<el-form-item label="封面编辑">
						<el-radio v-model="form.photoType" :label="1" border>无封面</el-radio>
						<!-- <el-radio v-model="form.photoType" :label="2" border>
							自动截取视频首帧
						</el-radio> -->
						<el-radio v-model="form.photoType" :label="3" border>
							上传指定封面图片
						</el-radio>
					</el-form-item>
					<el-form-item v-if="form.photoType == 3" label="封面图片" prop="coverPhotoUrl">
						<uploadVideoImg
							:file-list.sync="form.coverPhotoUrl"
							noName
						></uploadVideoImg>
					</el-form-item>
					<el-form-item label="视频标签">
						<el-cascader
							v-model="form.videoLabelId"
							:options="videoLabelOptions"
							:props="{ value: 'id', emitPath: false }"
						></el-cascader>
					</el-form-item>
					<el-form-item label="知识点标签">
						<el-cascader
							v-model="form.label"
							:options="labelTreeOptions"
							:props="{ value: 'id', emitPath: false }"
						></el-cascader>
					</el-form-item>
					<el-form-item class="footer-btn">
						<el-button
							type="primary"
							size="medium"
							:loading="submitLoading"
							@click="clickSave"
						>
							保存
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</content-container>
	</page-container>
</template>
<script>
// import videoForm from '../components/edit/videoForm.vue'
import uploadVideo from '@/components/edit/uploadVideo.vue'
import uploadVideoImg from '../components/edit/uploadVideoImg.vue'
export default {
	components: {
		uploadVideo,
		uploadVideoImg,
	},
	data() {
		return {
			videoList: [],
			form: {
				videoLabelId: null,
				label: null,
				videoId: null,
				title: '',
				tvTitle: '',
				abstract: '',
				photoType: 1,
				coverPhotoUrl: [],
			},
			rules: {
				videoId: [{ required: true, message: '请选择视频', trigger: 'change' }],
				title: [{ required: true, message: '请输入视频标题', trigger: 'blur' }],
				tvTitle: [{ required: true, message: '请输入电视呈现标题', trigger: 'blur' }],
				abstract: [{ required: true, message: '请输入内容摘要', trigger: 'blur' }],
				// coverPhotoUrl: [{ required: true, message: '请选择封面图片', trigger: 'blur' }],
			},
			submitLoading: false,
			videoLabelOptions: [],
			labelTreeOptions: [],
		}
	},
	methods: {
		initForm() {
			this.form = {
				videoLabelId: null,
				label: null,
				videoId: null,
				title: '',
				tvTitle: '',
				abstract: '',
				photoType: 1,
				coverPhotoUrl: [],
			}
			const currentVideoId = Number(this.$route.query.id)
			if (currentVideoId) {
				this.$service.getVideoDetail({ id: currentVideoId }).then((res) => {
					const videoDetail = res.videoDetail
					this.form = {
						videoLabelId: videoDetail.videoLabelId,
						label: videoDetail.labelId,
						videoId: videoDetail.videoId,
						title: videoDetail.title,
						tvTitle: videoDetail.tvTitle,
						abstract: videoDetail.abstract,
						photoType: videoDetail.coverPhotoUrl ? 3 : 1,
						coverPhotoUrl: [{ url: videoDetail.coverPhotoUrl }],
					}
					this.videoList = [
						{ name: `videoId:${videoDetail.videoId}`, url: videoDetail.obsUrl },
					]
				})
			}
		},
		clickSave() {
			this.$refs['form'].validate((valid) => {
				console.log(this.form.coverPhotoUrl)
				if (valid) {
					const currentVideoId = Number(this.$route.query.id)
					const params = {
						id: currentVideoId,
						...this.form,
					}
					if (this.form.coverPhotoUrl.length) {
						params.coverPhotoUrl = this.form.coverPhotoUrl[0].response
							? this.form.coverPhotoUrl[0].response.Data.url
							: this.form.coverPhotoUrl[0].url
					} else {
						params.coverPhotoUrl = ''
					}
					if (currentVideoId) {
						// 修改
						this.$service.updateVideo(params).then(() => {
							this.$message({
								message: '修改成功！',
								type: 'success',
							})
							setTimeout(() => {
								this.$router.go(-1)
							}, 500)
						})
					} else {
						// 新增
						this.$service.addVideo(params).then(() => {
							this.$message({
								message: '添加成功！',
								type: 'success',
							})
							setTimeout(() => {
								this.$router.go(-1)
							}, 500)
						})
					}
				}
			})
		},
	},
	mounted() {
		this.initForm()
		this.$service.videoGraphList().then((res) => {
			this.videoLabelOptions = res.graph || []
		})
		this.$service
			.getKGTree({
				grade: 2,
				subject: 2,
				id: 0,
			})
			.then((res) => {
				this.labelTreeOptions = res.tree
			})
	},
}
</script>
<style lang="scss" scoped>
@import '@/assets/style/questionForm.scss';
.page-add-new-video {
	.el-cascader {
		width: 100%;
	}
	::v-deep .tip-title {
		display: inline-block;
		margin-left: 20px;
		color: #565656;
		font-weight: 400;
		span {
			color: #ff3838;
		}
	}
}
</style>
