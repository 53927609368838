<!--
 * @Author: wyq
 * @Date: 2021-06-21 09:58:06
 * @LastEditTime: 2021-10-27 14:49:14
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\components\edit\uploadImg.vue
-->
<template>
	<div class="upload-image">
		<el-upload
			:action="photoUrl"
			:headers="headers"
			name="video"
			:file-list="fileList"
			:on-change="handleChange"
			:on-success="onSuccess"
			accept=".mp4"
			:limit="1"
			:on-remove="onRemove"
		>
			<el-button size="small" type="primary" :disabled="Boolean(videoId)">
				<i class="el-icon-plus"></i>
				上传视频
			</el-button>
			<span></span>
		</el-upload>
	</div>
</template>
<script>
export default {
	props: {
		fileList: Array,
		noName: {
			type: Boolean,
			default: false,
		},
		videoId: String,
	},
	data() {
		return {
			currentFile: {},
			currentFileList: [],
		}
	},
	computed: {
		photoUrl() {
			return window.location.protocol + '//' + window.location.host + '/v1/video/upload'
		},
		headers() {
			return {
				Authorization: localStorage.getItem('token'),
			}
		},
	},
	methods: {
		handleChange(file, fileList) {
			file.label = '图' + fileList.length
			this.currentFileList = fileList
		},
		onSuccess(response, file, fileList) {
			console.log('onSuccess', response, file, fileList)
			this.$emit('update:fileList', fileList)
			this.$emit('update:videoId', response.videoId)
		},
		onRemove() {
			this.$emit('update:videoId', '')
		},
	},
}
</script>
<style lang="scss" scoped>
.upload-image {
}
</style>
